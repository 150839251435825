import { default as _91slug_933y16X4DEIbMeta } from "/vercel/path0/pages/articles/[slug].vue?macro=true";
import { default as indexHSIYyOmJPsMeta } from "/vercel/path0/pages/articles/index.vue?macro=true";
import { default as searchsnTo379jDRMeta } from "/vercel/path0/pages/campsite/search.vue?macro=true";
import { default as search97SfF7CzfaMeta } from "/vercel/path0/pages/dog-cafe/search.vue?macro=true";
import { default as searchBAiT6Y8nJ2Meta } from "/vercel/path0/pages/dog-hotel/search.vue?macro=true";
import { default as base8l5vKlx33MMeta } from "/vercel/path0/pages/dog-run/base.vue?macro=true";
import { default as searchF9f2aHKIDIMeta } from "/vercel/path0/pages/dog-run/search.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93M12rUi9E1dMeta } from "/vercel/path0/pages/news/[id].vue?macro=true";
import { default as searchqHHI60KXjwMeta } from "/vercel/path0/pages/pet-salon/search.vue?macro=true";
import { default as _91id_936bBLnUy6lCMeta } from "/vercel/path0/pages/places/[id].vue?macro=true";
import { default as indexZqjRDHar7KMeta } from "/vercel/path0/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: _91slug_933y16X4DEIbMeta?.name ?? "articles-slug",
    path: _91slug_933y16X4DEIbMeta?.path ?? "/articles/:slug()",
    meta: _91slug_933y16X4DEIbMeta || {},
    alias: _91slug_933y16X4DEIbMeta?.alias || [],
    redirect: _91slug_933y16X4DEIbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHSIYyOmJPsMeta?.name ?? "articles",
    path: indexHSIYyOmJPsMeta?.path ?? "/articles",
    meta: indexHSIYyOmJPsMeta || {},
    alias: indexHSIYyOmJPsMeta?.alias || [],
    redirect: indexHSIYyOmJPsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: searchsnTo379jDRMeta?.name ?? "campsite-search",
    path: searchsnTo379jDRMeta?.path ?? "/campsite/search",
    meta: searchsnTo379jDRMeta || {},
    alias: searchsnTo379jDRMeta?.alias || [],
    redirect: searchsnTo379jDRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campsite/search.vue").then(m => m.default || m)
  },
  {
    name: search97SfF7CzfaMeta?.name ?? "dog-cafe-search",
    path: search97SfF7CzfaMeta?.path ?? "/dog-cafe/search",
    meta: search97SfF7CzfaMeta || {},
    alias: search97SfF7CzfaMeta?.alias || [],
    redirect: search97SfF7CzfaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dog-cafe/search.vue").then(m => m.default || m)
  },
  {
    name: searchBAiT6Y8nJ2Meta?.name ?? "dog-hotel-search",
    path: searchBAiT6Y8nJ2Meta?.path ?? "/dog-hotel/search",
    meta: searchBAiT6Y8nJ2Meta || {},
    alias: searchBAiT6Y8nJ2Meta?.alias || [],
    redirect: searchBAiT6Y8nJ2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dog-hotel/search.vue").then(m => m.default || m)
  },
  {
    name: base8l5vKlx33MMeta?.name ?? "dog-run-base",
    path: base8l5vKlx33MMeta?.path ?? "/dog-run/base",
    meta: base8l5vKlx33MMeta || {},
    alias: base8l5vKlx33MMeta?.alias || [],
    redirect: base8l5vKlx33MMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dog-run/base.vue").then(m => m.default || m)
  },
  {
    name: searchF9f2aHKIDIMeta?.name ?? "dog-run-search",
    path: searchF9f2aHKIDIMeta?.path ?? "/dog-run/search",
    meta: searchF9f2aHKIDIMeta || {},
    alias: searchF9f2aHKIDIMeta?.alias || [],
    redirect: searchF9f2aHKIDIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dog-run/search.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93M12rUi9E1dMeta?.name ?? "news-id",
    path: _91id_93M12rUi9E1dMeta?.path ?? "/news/:id()",
    meta: _91id_93M12rUi9E1dMeta || {},
    alias: _91id_93M12rUi9E1dMeta?.alias || [],
    redirect: _91id_93M12rUi9E1dMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: searchqHHI60KXjwMeta?.name ?? "pet-salon-search",
    path: searchqHHI60KXjwMeta?.path ?? "/pet-salon/search",
    meta: searchqHHI60KXjwMeta || {},
    alias: searchqHHI60KXjwMeta?.alias || [],
    redirect: searchqHHI60KXjwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pet-salon/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_936bBLnUy6lCMeta?.name ?? "places-id",
    path: _91id_936bBLnUy6lCMeta?.path ?? "/places/:id()",
    meta: _91id_936bBLnUy6lCMeta || {},
    alias: _91id_936bBLnUy6lCMeta?.alias || [],
    redirect: _91id_936bBLnUy6lCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/places/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZqjRDHar7KMeta?.name ?? "privacy-policy",
    path: indexZqjRDHar7KMeta?.path ?? "/privacy-policy",
    meta: indexZqjRDHar7KMeta || {},
    alias: indexZqjRDHar7KMeta?.alias || [],
    redirect: indexZqjRDHar7KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy/index.vue").then(m => m.default || m)
  }
]